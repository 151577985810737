<template>
    <div class="projectsupport-container" @click="routerTo(dataItem.id)">
        <div class="img-container">
            <img :src="dataItem.thumbnail" alt="" />
            <span v-if="dataItem.status.text == '已完成'" class="span_1">{{ dataItem.status.text }}</span>
            <span v-if="dataItem.status.text == '实施中'" class="span_2">{{ dataItem.status.text }}</span>
            <span v-if="dataItem.status.text == '已发布'" class="span_3">{{ dataItem.status.text }}</span>
        </div>
        <div class="content-container" v-if="isShow">
            <p class="item-title">
                <label :class="(dataItem.project_type.project_type == '1') ? 'red' : (dataItem.project_type.project_type == '2'?'gray':'yellow')">{{ dataItem.project_type.text }}</label>
                {{ dataItem.title }}
            </p>
            <p class="item-address">{{ dataItem.province_id.text}}{{ dataItem.city_id.text}}{{ dataItem.twon_id.text}}</p>
            <div class="demanRaise-info">
                <p>
                    项目金额：<span>¥{{ dataItem.project_price }}元</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "project_item",
        props: {
            isShow: {
                type: Boolean,
                defaule: true
            },
            dataItem: {
                type: Object,
                default: {
                    id: null,
                    status: null,
                    project_type: {
                        text:"",
                    },
                    thumbnail: "",
                    project_price: "",
                    province_id: {
                        text:"",
                    },
                    city_id: {
                        text:"",
                    },
                    twon_id: {
                        text:"",
                    }
                },
                required: true
            }
        },
        data: () => {
            return {}
        },
        methods: {
            routerTo(id) {
                if (this.isShow) {
                    this.$router.push({ path: "/promotion/projectList/detail-"+id })
                }
            }
        }
    }
</script>

<style lang="scss">
    .projectsupport-container:hover {
        cursor: pointer;
        transform: scale(1.01);
    }

    .projectsupport-container {
        width: 100%;
        box-sizing: border-box;
        transition: all 0.1s;

        .img-container {
            display: block;
            width: 100%;
            height: 226px;
            object-fit: cover;
            position: relative;
            box-sizing: border-box;

            img {
                width: 100%;
                box-sizing: border-box;
                height: 226px;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                border-right: 1px solid $project-item-border-color;
                border-left: 1px solid $project-item-border-color;
                border-top: 1px solid $project-item-border-color;
            }

            span {
                box-sizing: border-box;
                position: absolute;
                top: -8px;
                left: 15px;
                display: block;
                padding: 6px 12px;
                font-size: 14px;
                color: #fff;
                line-height: normal;
                letter-spacing: 0;
                text-shadow: 0 1px 1px rgb(102 102 102 / 50%);
                z-index: 2;
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
            }
            .span_1 {
                background: $project-item-type-color-1 !important;
            }
            .span_2 {
                background: $project-item-type-color-2 !important;
            }
            .span_3 {
                background: $project-item-type-color-3 !important;
            }

            span::after {
                content: "";
                position: absolute;
                left: -8px;
                top: 1px;
                border: 4px solid #89540c;
                border-left-color: transparent;
                border-top-color: transparent;
            }
        }

        .content-container {
            width: 100%;
            height: 118px;
            box-sizing: border-box;
            padding: 10px 20px;
            border-right: 1px solid $project-item-border-color !important;
            border-left: 1px solid $project-item-border-color !important;
            border-bottom: 1px solid $project-item-border-color !important;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            margin-bottom: 20px;

            .item-title {
                height: 28px;
                font-size: 18px;
                line-height: 33px;
                font-weight: 600;
                color: $project-item-title-color;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                word-break: break-all;

                label {
                    font-size: 12px;
                    line-height: 30px;
                    color: #fff;
                    padding: 0px 4px;
                    margin-right: 5px;
                    border-radius: 6px;
                    display: inline-block;
                }

                label.red {
                    background-color: $base-color;
                }

                label.gray {
                    background-color: #8096b3;
                }

                label.yellow {
                    background-color: #ff850f;
                }
            }

            .item-address {
                padding-left: 17px;
                margin: 10px 0;
                background: url(https://www.zgshfp.com.cn/static/address.png) no-repeat left center;
                background-size: 11px 12px;
                font-size: 12px;
                color: #999;
                line-height: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-wrap: normal;
                word-break: break-all;
            }

            .demanRaise-info {
                color: #484848;
                font-size: 14px;

                span {
                    color: $base-color;
                }
            }
        }
    }
</style>
