<template>
    <div class="box">
        <div class="pro_query_line" v-for="(itemA, indexA) in listNumber" :key="indexA" v-if="indexA == 0 || isShowAll == true">
            <div class="title">
                <span v-if="indexA == 0">{{ activeTitle }}：</span>
            </div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane v-if="index < (indexA + 1) * listItemNumber && index >= indexA * listItemNumber" v-for="(item, index) in activeList" :key="item.id" :label="item.name" :name="item.id.toString()"> </el-tab-pane>
            </el-tabs>
            <div class="pro_right">
                <div @click="changeIsShow" v-if="indexA == 0 && listNumber > 1" >
                    <i class="el-collapse-item__arrow el-icon-arrow-right is-active" v-if="!isShowAll" title="查看更多"></i>
                    <i class="el-icon-arrow-right" v-else title="隐藏显示"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "project_tabs",
        props: {
            // 每行tabs的个数
            listItemNumber: {
                type: Number,
                default: 12
            },
            // 左侧标题（按行业）
            activeTitle: {
                type: String,
                default: "",
                required: true
            },
            // 默认停留的值位置
            activeIndex: {
                type: String,
                default: null,
                required: true
            },
            // tabs值列表
            activeList: {
                type: Array,
                default: [
                    {
                        id: "0",
                        name: "全部"
                    }
                ],
                required: true
            }
        },
        data: () => {
            return {
                activeName: "",
                listNumber: 1,
                isShowAll: false
            }
        },
        created() {
            // 获取tabs分组后的行数
            this.setListNumber()
            this.activeName = this.activeIndex
        },
        methods: {
            // 改变isShow状态
            changeIsShow() {
                this.isShowAll = !this.isShowAll
            },
            // 获取tabs分组后的行数
            setListNumber() {
                this.listNumber = parseInt(this.activeList.length / this.listItemNumber) + 1
            },
            handleClick(tab, event) {
                this.$emit("setActiveIndex", this.activeName)
            }
        }
    }
</script>

<style lang="scss">
    .box {
        box-sizing: border-box;
        width: 100%;

        .pro_query_line {
            box-sizing: border-box;
            width: 100%;
            display: flex;
            justify-content: flex-start;

            .title {
                width: 62px;
            }
            .el-tabs {
                width: 998px !important;
            }
        }
    }
    .el-tabs__item.is-active {
        color: $base-color !important;
    }
    .el-tabs__active-bar {
        background-color: $base-color !important;
    }
    .el-tabs__item:hover {
        color: $base-color !important;
    }
    .el-tabs__nav-scroll {
        width: 1020px !important;
        height: auto !important;
        display: flex !important;
        justify-content: flex-start !important;
        flex-wrap: wrap !important;
        overflow: unset !important;

        .el-tabs__nav {
            width: 1020px !important;
            height: auto !important;
            display: flex !important;
            justify-content: flex-start !important;
            flex-wrap: wrap !important;
            overflow: unset !important;
        }
    }
    .el-tabs__nav-wrap::after {
        content: "" !important;
        position: absolute !important;
        left: 0 !important;
        bottom: 0 !important;
        width: 100% !important;
        height: 2px !important;
        background-color: #e4e7ed !important;
        z-index: 1 !important;
    }
    .pro_right {
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        div {
            width: 20px;
            height: 22px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;

            .el-collapse-item__arrow,
            .el-icon-arrow-right {
                font-size: 20px !important;
            }
        }
    }
</style>
